/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    ol: "ol",
    li: "li",
    ul: "ul"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "diplacusis-understanding-the-causes-and-treatments-of-double-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diplacusis-understanding-the-causes-and-treatments-of-double-hearing",
    "aria-label": "diplacusis understanding the causes and treatments of double hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diplacusis: Understanding the causes and treatments of double hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Diplacusis, a lesser-known auditory phenomenon, refers to a condition where a person perceives the same sound at different pitches in each ear. This dissonance can lead to difficulties in understanding speech, music, and other auditory stimuli. In this article, we will explore the types, causes, and treatment options for diplacusis."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "types-of-diplacusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#types-of-diplacusis",
    "aria-label": "types of diplacusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Types of Diplacusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Interaural Diplacusis:"), " This type occurs when a person perceives sounds at different pitches in each ear, creating a noticeable discrepancy in the way they hear stereo or binaural sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Intrachannel Diplacusis:"), " In this type, the individual experiences a pitch discrepancy within a single ear, making it challenging to discern between similar sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Diploacusis Dysharmonica also known as “double hearing”:"), " This condition involves hearing the same sound at two different pitches, but the pitches are harmonically related. In simpler terms, it's as if one is hearing an echo of the original sound, creating a harmonic discord."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-diplacusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-diplacusis",
    "aria-label": "causes of diplacusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of Diplacusis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Diplacusis can be caused by various factors, including:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Age-Related Hearing Loss:"), " As individuals age, their hearing abilities may decline, leading to diplacusis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Noise-Induced Hearing Loss:"), " Prolonged exposure to loud noises can damage the delicate structures of the inner ear, causing diplacusis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Ear Infections:"), " Infections affecting the ear can disrupt the normal functioning of the auditory system, leading to diplacusis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Ototoxic Medications:"), " Certain medications have the potential to cause damage to the auditory system, resulting in diplacusis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Conductive Hearing Loss:"), " Damage to the middle or outer ear can contribute to diplacusis, especially when it disrupts the transmission of sound waves to the inner ear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "duration-and-resolution-of-diplacusis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#duration-and-resolution-of-diplacusis",
    "aria-label": "duration and resolution of diplacusis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Duration and Resolution of Diplacusis:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Diplacusis, like many auditory conditions, can vary widely in terms of duration and resolution. Several factors influence the outcome, including the underlying cause, the individual's overall health, and the timeliness of intervention. Here are key considerations:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Temporary Diplacusis: In some cases, diplacusis may be a temporary condition. For instance, if it is caused by an ear infection or exposure to loud noises, the symptoms may subside once the underlying issue is addressed."), "\n"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Permanent Diplacusis: On the other hand, diplacusis can be permanent, especially if it results from irreversible damage to the auditory system, such as age-related ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), " or certain neurological conditions."), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diplacusis-treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diplacusis-treatment",
    "aria-label": "diplacusis treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diplacusis Treatment"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Management with Hearing Aids:"), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Amplification: Hearing aids are a cornerstone in managing diplacusis. By amplifying sounds and providing customized settings, hearing aids help individuals perceive a more balanced and harmonious auditory experience."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Directional Microphones: ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Modern hearing aids"), " often come equipped with directional microphones, allowing users to focus on specific sounds while minimizing background noise. This feature can be particularly helpful for those with diplacusis in crowded or noisy environments."), "\n"), "\n", React.createElement(_components.ol, {
    start: "2",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Customized Programming:"), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Frequency Shaping: Audiologists can tailor the frequency response of hearing aids to address the specific pitch discrepancies experienced by individuals with diplacusis. This customization ensures that the amplification is finely tuned to their unique needs."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Real-Time Adjustments: Some advanced hearing aids allow real-time adjustments, empowering users to adapt the settings based on immediate preferences and the nature of the sounds they encounter."), "\n"), "\n", React.createElement(_components.ol, {
    start: "3",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Audiological Interventions:"), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Regular Monitoring: Audiologists play a crucial role in the ongoing management of diplacusis. Regular audiometric assessments help monitor changes in hearing abilities and allow for adjustments to hearing aid settings as needed."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Counseling: Audiologists can provide counseling to individuals with diplacusis, helping them cope with the challenges and providing guidance on communication strategies and auditory training."), "\n"), "\n", React.createElement(_components.ol, {
    start: "4",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Addressing Underlying Causes:"), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear Infections: If the cause of diplacusis is an ear infection, prompt medical intervention with antibiotics can help resolve the infection, leading to an improvement in symptoms."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noise-Induced Hearing Loss: Prevention is crucial in cases of noise-induced diplacusis. Individuals should use hearing protection in loud environments to prevent further damage."), "\n"), "\n", React.createElement(_components.ol, {
    start: "5",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Medical Consultation:"), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Neurological Conditions: If diplacusis is associated with neurological conditions, such as traumatic auditory neuropathy, individuals may benefit from consultation with neurologists or other specialists for comprehensive management."), "\n"), "\n", React.createElement(_components.ol, {
    start: "6",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing Rehabilitation:"), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Auditory Training: Hearing rehabilitation programs, including auditory training exercises, can be beneficial in improving the brain's ability to interpret and process sounds accurately."), "\n"), "\n", React.createElement(_components.ol, {
    start: "7",
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Patient Education:"), "\n"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Understanding and Coping Strategies: Educating individuals with diplacusis about their condition and providing coping strategies can enhance their ability to adapt to the challenges they may face in various listening environments."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearcom-hearing-specialist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearcom-hearing-specialist",
    "aria-label": "contact a hearcom hearing specialist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hear.com hearing specialist"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you or a loved one is experiencing symptoms of diplacusis, seeking professional help is essential. At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we connect individuals with the top 2% of the best audiologists in the United States. Our team of hearing experts is dedicated to guiding you towards better hearing. Explore our range of top-notch devices, insurance options, and flexible financing plans. No matter your budget, hear.com is here to help you regain a fulfilling auditory experience. Contact us today to embark on your journey to better hearing."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-Risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
